import React from 'react';

const CalltoActionSeven = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-7">
            <div className="container">
            </div>
        </div>
    )
}
export default CalltoActionSeven;